import { Abi } from 'viem';

export const Referral: Abi = [
  { type: 'constructor', inputs: [], stateMutability: 'nonpayable' },
  {
    type: 'function',
    name: 'acceptCodeOwnership',
    inputs: [{ name: '_code', type: 'bytes32', internalType: 'bytes32' }],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'codeOwners',
    inputs: [{ name: '', type: 'bytes32', internalType: 'bytes32' }],
    outputs: [{ name: '', type: 'address', internalType: 'address' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'codes',
    inputs: [{ name: '', type: 'address', internalType: 'address' }],
    outputs: [{ name: '', type: 'bytes32', internalType: 'bytes32' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'getTraderReferralInfo',
    inputs: [{ name: '_account', type: 'address', internalType: 'address' }],
    outputs: [
      { name: '', type: 'bytes32', internalType: 'bytes32' },
      { name: '', type: 'address', internalType: 'address' },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'gov',
    inputs: [],
    outputs: [{ name: '', type: 'address', internalType: 'address' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'govSetCodeOwner',
    inputs: [
      { name: '_code', type: 'bytes32', internalType: 'bytes32' },
      { name: '_newAccount', type: 'address', internalType: 'address' },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'isHandler',
    inputs: [{ name: '', type: 'address', internalType: 'address' }],
    outputs: [{ name: '', type: 'bool', internalType: 'bool' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'pendingCodeOwners',
    inputs: [{ name: '', type: 'bytes32', internalType: 'bytes32' }],
    outputs: [{ name: '', type: 'address', internalType: 'address' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'referrerTiers',
    inputs: [{ name: '', type: 'address', internalType: 'address' }],
    outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'registerCode',
    inputs: [{ name: '_code', type: 'bytes32', internalType: 'bytes32' }],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'requestGov',
    inputs: [{ name: '_gov', type: 'address', internalType: 'address' }],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'requestedGov',
    inputs: [],
    outputs: [{ name: '', type: 'address', internalType: 'address' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'setGov',
    inputs: [{ name: '_gov', type: 'address', internalType: 'address' }],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'setHandler',
    inputs: [
      { name: '_handler', type: 'address', internalType: 'address' },
      { name: '_isActive', type: 'bool', internalType: 'bool' },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'setPendingCodeOwnershipTransfer',
    inputs: [
      { name: '_code', type: 'bytes32', internalType: 'bytes32' },
      { name: '_newAccount', type: 'address', internalType: 'address' },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'setReferrerTier',
    inputs: [
      { name: '_referrer', type: 'address', internalType: 'address' },
      { name: '_tierId', type: 'uint256', internalType: 'uint256' },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'setTier',
    inputs: [
      { name: '_tierId', type: 'uint256', internalType: 'uint256' },
      {
        name: '_feeDiscountPct',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: '_refRebatePct',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'setTraderReferralCode',
    inputs: [
      { name: '_account', type: 'address', internalType: 'address' },
      { name: '_code', type: 'bytes32', internalType: 'bytes32' },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'setTraderReferralCodeByUser',
    inputs: [{ name: '_code', type: 'bytes32', internalType: 'bytes32' }],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'tiers',
    inputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
    outputs: [
      {
        name: 'feeDiscountPct',
        type: 'uint256',
        internalType: 'uint256',
      },
      { name: 'refRebatePct', type: 'uint256', internalType: 'uint256' },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'traderReferralCodes',
    inputs: [{ name: '', type: 'address', internalType: 'address' }],
    outputs: [{ name: '', type: 'bytes32', internalType: 'bytes32' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'traderReferralDiscount',
    inputs: [
      { name: '_account', type: 'address', internalType: 'address' },
      { name: '_fee', type: 'uint256', internalType: 'uint256' },
    ],
    outputs: [
      {
        name: 'traderFeesPostDiscount',
        type: 'uint256',
        internalType: 'uint256',
      },
      { name: 'referrer', type: 'address', internalType: 'address' },
      { name: 'rebateShare', type: 'uint256', internalType: 'uint256' },
    ],
    stateMutability: 'view',
  },
  {
    type: 'event',
    name: 'GovSetCodeOwner',
    inputs: [
      {
        name: 'code',
        type: 'bytes32',
        indexed: false,
        internalType: 'bytes32',
      },
      {
        name: 'newAccount',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'RegisterCode',
    inputs: [
      {
        name: 'account',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
      {
        name: 'code',
        type: 'bytes32',
        indexed: false,
        internalType: 'bytes32',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'SetCodeOwner',
    inputs: [
      {
        name: 'oldOwner',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
      {
        name: 'newOwner',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
      {
        name: 'code',
        type: 'bytes32',
        indexed: false,
        internalType: 'bytes32',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'SetGov',
    inputs: [
      {
        name: 'gov',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'SetHandler',
    inputs: [
      {
        name: 'handler',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
      {
        name: 'isActive',
        type: 'bool',
        indexed: false,
        internalType: 'bool',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'SetPendingCodeOwner',
    inputs: [
      {
        name: 'account',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
      {
        name: 'newAccount',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
      {
        name: 'code',
        type: 'bytes32',
        indexed: false,
        internalType: 'bytes32',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'SetReferrerDiscountShare',
    inputs: [
      {
        name: 'referrer',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
      {
        name: 'discountShare',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'SetReferrerTier',
    inputs: [
      {
        name: 'referrer',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
      {
        name: 'tierId',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'SetTier',
    inputs: [
      {
        name: 'tierId',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'feeDiscountPct',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'refRebatePct',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'SetTraderReferralCode',
    inputs: [
      {
        name: 'account',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
      {
        name: 'code',
        type: 'bytes32',
        indexed: false,
        internalType: 'bytes32',
      },
    ],
    anonymous: false,
  },
] as const;
