export const TradingStorage = [
  { inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'string', name: 'name', type: 'string' },
      { indexed: false, internalType: 'address', name: 'a', type: 'address' },
    ],
    name: 'AddressUpdated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: '_trader', type: 'address' },
      { indexed: false, internalType: 'uint256', name: '_pairIndex', type: 'uint256' },
      { indexed: false, internalType: 'bool', name: '_buy', type: 'bool' },
      { indexed: false, internalType: 'uint256', name: 'fee', type: 'uint256' },
    ],
    name: 'FeesCharged',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'uint8', name: 'version', type: 'uint8' }],
    name: 'Initialized',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'orderId', type: 'uint256' },
      { indexed: true, internalType: 'address', name: 'trader', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'pairIndex', type: 'uint256' },
    ],
    name: 'MarketOpenCanceled',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'string', name: 'name', type: 'string' },
      { indexed: false, internalType: 'uint256', name: 'value', type: 'uint256' },
    ],
    name: 'NumberUpdated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'string', name: 'name', type: 'string' },
      { indexed: false, internalType: 'uint256', name: 'pairIndex', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'value', type: 'uint256' },
    ],
    name: 'NumberUpdatedPair',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'bool', name: '_open', type: 'bool' },
      { indexed: false, internalType: 'bool', name: '_long', type: 'bool' },
      { indexed: false, internalType: 'uint256', name: '_pairIndex', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: '_leveragedPos', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: '_price', type: 'uint256' },
    ],
    name: 'OIUpdated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'address', name: 'a', type: 'address' }],
    name: 'SupportedTokenAdded',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: '_trader', type: 'address' },
      { indexed: false, internalType: 'address', name: '_referrer', type: 'address' },
      { indexed: false, internalType: 'uint256', name: '_leveragedPosition', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: '_traderFeePostDiscount', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: '_traderFeeDiscount', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: '_referrerRebate', type: 'uint256' },
    ],
    name: 'TradeReferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'address', name: 'a', type: 'address' }],
    name: 'TradingContractAdded',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'address', name: 'a', type: 'address' }],
    name: 'TradingContractRemoved',
    type: 'event',
  },
  {
    inputs: [{ internalType: 'address', name: '__trading', type: 'address' }],
    name: 'addTradingContract',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_trader', type: 'address' },
      { internalType: 'uint256', name: '_fees', type: 'uint256' },
      { internalType: 'uint256', name: '_leveragedPosition', type: 'uint256' },
    ],
    name: 'applyReferral',
    outputs: [
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    name: 'blockOI',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'callbacks',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  { inputs: [], name: 'claimFees', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  { inputs: [], name: 'claimRebate', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [],
    name: 'dev',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'devFeesUSDC',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'trader', type: 'address' },
      { internalType: 'uint256', name: 'pairIndex', type: 'uint256' },
    ],
    name: 'firstEmptyOpenLimitIndex',
    outputs: [{ internalType: 'uint256', name: 'index', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'trader', type: 'address' },
      { internalType: 'uint256', name: 'pairIndex', type: 'uint256' },
    ],
    name: 'firstEmptyTradeIndex',
    outputs: [{ internalType: 'uint256', name: 'index', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '_id', type: 'uint256' }],
    name: 'forceUnregisterPendingMarketOrder',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_trader', type: 'address' },
      { internalType: 'uint256', name: '_pairIndex', type: 'uint256' },
      { internalType: 'uint256', name: '_index', type: 'uint256' },
    ],
    name: 'getOpenLimitOrder',
    outputs: [
      {
        components: [
          { internalType: 'address', name: 'trader', type: 'address' },
          { internalType: 'uint256', name: 'pairIndex', type: 'uint256' },
          { internalType: 'uint256', name: 'index', type: 'uint256' },
          { internalType: 'uint256', name: 'positionSize', type: 'uint256' },
          { internalType: 'bool', name: 'buy', type: 'bool' },
          { internalType: 'uint256', name: 'leverage', type: 'uint256' },
          { internalType: 'uint256', name: 'tp', type: 'uint256' },
          { internalType: 'uint256', name: 'sl', type: 'uint256' },
          { internalType: 'uint256', name: 'price', type: 'uint256' },
          { internalType: 'uint256', name: 'slippageP', type: 'uint256' },
          { internalType: 'uint256', name: 'block', type: 'uint256' },
          { internalType: 'uint256', name: 'executionFee', type: 'uint256' },
        ],
        internalType: 'struct ITradingStorage.OpenLimitOrder',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getOpenLimitOrders',
    outputs: [
      {
        components: [
          { internalType: 'address', name: 'trader', type: 'address' },
          { internalType: 'uint256', name: 'pairIndex', type: 'uint256' },
          { internalType: 'uint256', name: 'index', type: 'uint256' },
          { internalType: 'uint256', name: 'positionSize', type: 'uint256' },
          { internalType: 'bool', name: 'buy', type: 'bool' },
          { internalType: 'uint256', name: 'leverage', type: 'uint256' },
          { internalType: 'uint256', name: 'tp', type: 'uint256' },
          { internalType: 'uint256', name: 'sl', type: 'uint256' },
          { internalType: 'uint256', name: 'price', type: 'uint256' },
          { internalType: 'uint256', name: 'slippageP', type: 'uint256' },
          { internalType: 'uint256', name: 'block', type: 'uint256' },
          { internalType: 'uint256', name: 'executionFee', type: 'uint256' },
        ],
        internalType: 'struct ITradingStorage.OpenLimitOrder[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_trader', type: 'address' }],
    name: 'getPendingOrderIds',
    outputs: [{ internalType: 'uint256[]', name: '', type: 'uint256[]' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getUsdOI',
    outputs: [{ internalType: 'uint256[2]', name: '', type: 'uint256[2]' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'gov',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'govFeesUSDC',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'govTreasury',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_trader', type: 'address' },
      { internalType: 'uint256', name: '_pairIndex', type: 'uint256' },
      { internalType: 'uint256', name: '_leveragedPositionSize', type: 'uint256' },
      { internalType: 'bool', name: '_usdc', type: 'bool' },
      { internalType: 'bool', name: '_fullFee', type: 'bool' },
      { internalType: 'bool', name: '_buy', type: 'bool' },
    ],
    name: 'handleDevGovFees',
    outputs: [{ internalType: 'uint256', name: 'feeAfterRebate', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'trader', type: 'address' },
      { internalType: 'uint256', name: 'pairIndex', type: 'uint256' },
      { internalType: 'uint256', name: 'index', type: 'uint256' },
    ],
    name: 'hasOpenLimitOrder',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'devFees', type: 'uint256' },
      { internalType: 'uint256', name: 'govFees', type: 'uint256' },
    ],
    name: 'incrementClosingFees',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  { inputs: [], name: 'initialize', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'isTradingContract',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'maxOpenInterest',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'maxPendingMarketOrders',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'maxTradesPerPair',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    name: 'openInterestUSDC',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    name: 'openLimitOrderIds',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'openLimitOrders',
    outputs: [
      { internalType: 'address', name: 'trader', type: 'address' },
      { internalType: 'uint256', name: 'pairIndex', type: 'uint256' },
      { internalType: 'uint256', name: 'index', type: 'uint256' },
      { internalType: 'uint256', name: 'positionSize', type: 'uint256' },
      { internalType: 'bool', name: 'buy', type: 'bool' },
      { internalType: 'uint256', name: 'leverage', type: 'uint256' },
      { internalType: 'uint256', name: 'tp', type: 'uint256' },
      { internalType: 'uint256', name: 'sl', type: 'uint256' },
      { internalType: 'uint256', name: 'price', type: 'uint256' },
      { internalType: 'uint256', name: 'slippageP', type: 'uint256' },
      { internalType: 'uint256', name: 'block', type: 'uint256' },
      { internalType: 'uint256', name: 'executionFee', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    name: 'openLimitOrdersCount',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_trader', type: 'address' },
      { internalType: 'uint256', name: '_pairIndex', type: 'uint256' },
      { internalType: 'uint256', name: '_index', type: 'uint256' },
    ],
    name: 'openTrades',
    outputs: [
      {
        components: [
          { internalType: 'address', name: 'trader', type: 'address' },
          { internalType: 'uint256', name: 'pairIndex', type: 'uint256' },
          { internalType: 'uint256', name: 'index', type: 'uint256' },
          { internalType: 'uint256', name: 'initialPosToken', type: 'uint256' },
          { internalType: 'uint256', name: 'positionSizeUSDC', type: 'uint256' },
          { internalType: 'uint256', name: 'openPrice', type: 'uint256' },
          { internalType: 'bool', name: 'buy', type: 'bool' },
          { internalType: 'uint256', name: 'leverage', type: 'uint256' },
          { internalType: 'uint256', name: 'tp', type: 'uint256' },
          { internalType: 'uint256', name: 'sl', type: 'uint256' },
          { internalType: 'uint256', name: 'timestamp', type: 'uint256' },
        ],
        internalType: 'struct ITradingStorage.Trade',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_trader', type: 'address' },
      { internalType: 'uint256', name: '_pairIndex', type: 'uint256' },
    ],
    name: 'openTradesCount',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_trader', type: 'address' },
      { internalType: 'uint256', name: '_pairIndex', type: 'uint256' },
      { internalType: 'uint256', name: '_index', type: 'uint256' },
    ],
    name: 'openTradesInfo',
    outputs: [
      {
        components: [
          { internalType: 'uint256', name: 'openInterestUSDC', type: 'uint256' },
          { internalType: 'uint256', name: 'tpLastUpdated', type: 'uint256' },
          { internalType: 'uint256', name: 'slLastUpdated', type: 'uint256' },
          { internalType: 'bool', name: 'beingMarketClosed', type: 'bool' },
          { internalType: 'uint256', name: 'lossProtection', type: 'uint256' },
        ],
        internalType: 'struct ITradingStorage.TradeInfo',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '_pairIndex', type: 'uint256' }],
    name: 'pairOI',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    name: 'pairTraders',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '_pairIndex', type: 'uint256' }],
    name: 'pairTradersArray',
    outputs: [{ internalType: 'address[]', name: '', type: 'address[]' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    name: 'pairTradersId',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    name: 'pendingMarketCloseCount',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    name: 'pendingMarketOpenCount',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    name: 'pendingOrderIds',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_trader', type: 'address' }],
    name: 'pendingOrderIdsCount',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'priceAggregator',
    outputs: [{ internalType: 'contract IPriceAggregator', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'rebates',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'referral',
    outputs: [{ internalType: 'contract IReferral', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'trader', type: 'address' },
      { internalType: 'uint256', name: 'pairIndex', type: 'uint256' },
      { internalType: 'uint256', name: 'index', type: 'uint256' },
      { internalType: 'uint256', name: '_amountReduced', type: 'uint256' },
    ],
    name: 'registerPartialTrade',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '__trading', type: 'address' }],
    name: 'removeTradingContract',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'orderId', type: 'uint256' }],
    name: 'reqIDpendingLimitOrder',
    outputs: [
      {
        components: [
          { internalType: 'address', name: 'trader', type: 'address' },
          { internalType: 'uint256', name: 'pairIndex', type: 'uint256' },
          { internalType: 'uint256', name: 'index', type: 'uint256' },
          { internalType: 'enum ITradingStorage.LimitOrder', name: 'orderType', type: 'uint8' },
        ],
        internalType: 'struct ITradingStorage.PendingLimitOrder',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'orderId', type: 'uint256' }],
    name: 'reqIDpendingMarketOrder',
    outputs: [
      {
        components: [
          {
            components: [
              { internalType: 'address', name: 'trader', type: 'address' },
              { internalType: 'uint256', name: 'pairIndex', type: 'uint256' },
              { internalType: 'uint256', name: 'index', type: 'uint256' },
              { internalType: 'uint256', name: 'initialPosToken', type: 'uint256' },
              { internalType: 'uint256', name: 'positionSizeUSDC', type: 'uint256' },
              { internalType: 'uint256', name: 'openPrice', type: 'uint256' },
              { internalType: 'bool', name: 'buy', type: 'bool' },
              { internalType: 'uint256', name: 'leverage', type: 'uint256' },
              { internalType: 'uint256', name: 'tp', type: 'uint256' },
              { internalType: 'uint256', name: 'sl', type: 'uint256' },
              { internalType: 'uint256', name: 'timestamp', type: 'uint256' },
            ],
            internalType: 'struct ITradingStorage.Trade',
            name: 'trade',
            type: 'tuple',
          },
          { internalType: 'uint256', name: 'block', type: 'uint256' },
          { internalType: 'uint256', name: 'wantedPrice', type: 'uint256' },
          { internalType: 'uint256', name: 'slippageP', type: 'uint256' },
        ],
        internalType: 'struct ITradingStorage.PendingMarketOrder',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_gov', type: 'address' }],
    name: 'requestGov',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'requestedGov',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '__callbacks', type: 'address' }],
    name: 'setCallbacks',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_dev', type: 'address' }],
    name: 'setDev',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_newGov', type: 'address' }],
    name: 'setGov',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_govTreasury', type: 'address' }],
    name: 'setGovTreasury',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '_maxPendingMarketOrders', type: 'uint256' }],
    name: 'setMaxPendingMarketOrders',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '_maxTradesPerPair', type: 'uint256' }],
    name: 'setMaxTradesPerPair',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_aggregator', type: 'address' }],
    name: 'setPriceAggregator',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_refferal', type: 'address' }],
    name: 'setReferral',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '__trading', type: 'address' }],
    name: 'setTrading',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '_newCap', type: 'uint256' }],
    name: 'setTvlCap',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_token', type: 'address' }],
    name: 'setUSDC',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_vaultManager', type: 'address' }],
    name: 'setVaultManager',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'address', name: 'trader', type: 'address' },
          { internalType: 'uint256', name: 'pairIndex', type: 'uint256' },
          { internalType: 'uint256', name: 'index', type: 'uint256' },
          { internalType: 'uint256', name: 'positionSize', type: 'uint256' },
          { internalType: 'bool', name: 'buy', type: 'bool' },
          { internalType: 'uint256', name: 'leverage', type: 'uint256' },
          { internalType: 'uint256', name: 'tp', type: 'uint256' },
          { internalType: 'uint256', name: 'sl', type: 'uint256' },
          { internalType: 'uint256', name: 'price', type: 'uint256' },
          { internalType: 'uint256', name: 'slippageP', type: 'uint256' },
          { internalType: 'uint256', name: 'block', type: 'uint256' },
          { internalType: 'uint256', name: 'executionFee', type: 'uint256' },
        ],
        internalType: 'struct ITradingStorage.OpenLimitOrder',
        name: 'o',
        type: 'tuple',
      },
    ],
    name: 'storeOpenLimitOrder',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'address', name: 'trader', type: 'address' },
          { internalType: 'uint256', name: 'pairIndex', type: 'uint256' },
          { internalType: 'uint256', name: 'index', type: 'uint256' },
          { internalType: 'enum ITradingStorage.LimitOrder', name: 'orderType', type: 'uint8' },
        ],
        internalType: 'struct ITradingStorage.PendingLimitOrder',
        name: '_limitOrder',
        type: 'tuple',
      },
      { internalType: 'uint256', name: '_orderId', type: 'uint256' },
    ],
    name: 'storePendingLimitOrder',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            components: [
              { internalType: 'address', name: 'trader', type: 'address' },
              { internalType: 'uint256', name: 'pairIndex', type: 'uint256' },
              { internalType: 'uint256', name: 'index', type: 'uint256' },
              { internalType: 'uint256', name: 'initialPosToken', type: 'uint256' },
              { internalType: 'uint256', name: 'positionSizeUSDC', type: 'uint256' },
              { internalType: 'uint256', name: 'openPrice', type: 'uint256' },
              { internalType: 'bool', name: 'buy', type: 'bool' },
              { internalType: 'uint256', name: 'leverage', type: 'uint256' },
              { internalType: 'uint256', name: 'tp', type: 'uint256' },
              { internalType: 'uint256', name: 'sl', type: 'uint256' },
              { internalType: 'uint256', name: 'timestamp', type: 'uint256' },
            ],
            internalType: 'struct ITradingStorage.Trade',
            name: 'trade',
            type: 'tuple',
          },
          { internalType: 'uint256', name: 'block', type: 'uint256' },
          { internalType: 'uint256', name: 'wantedPrice', type: 'uint256' },
          { internalType: 'uint256', name: 'slippageP', type: 'uint256' },
        ],
        internalType: 'struct ITradingStorage.PendingMarketOrder',
        name: '_order',
        type: 'tuple',
      },
      { internalType: 'uint256', name: '_id', type: 'uint256' },
      { internalType: 'bool', name: '_open', type: 'bool' },
    ],
    name: 'storePendingMarketOrder',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'address', name: 'trader', type: 'address' },
          { internalType: 'uint256', name: 'pairIndex', type: 'uint256' },
          { internalType: 'uint256', name: 'index', type: 'uint256' },
          { internalType: 'uint256', name: 'initialPosToken', type: 'uint256' },
          { internalType: 'uint256', name: 'positionSizeUSDC', type: 'uint256' },
          { internalType: 'uint256', name: 'openPrice', type: 'uint256' },
          { internalType: 'bool', name: 'buy', type: 'bool' },
          { internalType: 'uint256', name: 'leverage', type: 'uint256' },
          { internalType: 'uint256', name: 'tp', type: 'uint256' },
          { internalType: 'uint256', name: 'sl', type: 'uint256' },
          { internalType: 'uint256', name: 'timestamp', type: 'uint256' },
        ],
        internalType: 'struct ITradingStorage.Trade',
        name: '_trade',
        type: 'tuple',
      },
      {
        components: [
          { internalType: 'uint256', name: 'openInterestUSDC', type: 'uint256' },
          { internalType: 'uint256', name: 'tpLastUpdated', type: 'uint256' },
          { internalType: 'uint256', name: 'slLastUpdated', type: 'uint256' },
          { internalType: 'bool', name: 'beingMarketClosed', type: 'bool' },
          { internalType: 'uint256', name: 'lossProtection', type: 'uint256' },
        ],
        internalType: 'struct ITradingStorage.TradeInfo',
        name: '_tradeInfo',
        type: 'tuple',
      },
    ],
    name: 'storeTrade',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalOI',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'trading',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_from', type: 'address' },
      { internalType: 'address', name: '_to', type: 'address' },
      { internalType: 'uint256', name: '_amount', type: 'uint256' },
    ],
    name: 'transferUSDC',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'tvlCap',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_trader', type: 'address' },
      { internalType: 'uint256', name: '_pairIndex', type: 'uint256' },
      { internalType: 'uint256', name: '_index', type: 'uint256' },
    ],
    name: 'unregisterOpenLimitOrder',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '_order', type: 'uint256' }],
    name: 'unregisterPendingLimitOrder',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_id', type: 'uint256' },
      { internalType: 'bool', name: '_open', type: 'bool' },
    ],
    name: 'unregisterPendingMarketOrder',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'trader', type: 'address' },
      { internalType: 'uint256', name: 'pairIndex', type: 'uint256' },
      { internalType: 'uint256', name: 'index', type: 'uint256' },
    ],
    name: 'unregisterTrade',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'address', name: 'trader', type: 'address' },
          { internalType: 'uint256', name: 'pairIndex', type: 'uint256' },
          { internalType: 'uint256', name: 'index', type: 'uint256' },
          { internalType: 'uint256', name: 'positionSize', type: 'uint256' },
          { internalType: 'bool', name: 'buy', type: 'bool' },
          { internalType: 'uint256', name: 'leverage', type: 'uint256' },
          { internalType: 'uint256', name: 'tp', type: 'uint256' },
          { internalType: 'uint256', name: 'sl', type: 'uint256' },
          { internalType: 'uint256', name: 'price', type: 'uint256' },
          { internalType: 'uint256', name: 'slippageP', type: 'uint256' },
          { internalType: 'uint256', name: 'block', type: 'uint256' },
          { internalType: 'uint256', name: 'executionFee', type: 'uint256' },
        ],
        internalType: 'struct ITradingStorage.OpenLimitOrder',
        name: '_o',
        type: 'tuple',
      },
    ],
    name: 'updateOpenLimitOrder',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_trader', type: 'address' },
      { internalType: 'uint256', name: '_pairIndex', type: 'uint256' },
      { internalType: 'uint256', name: '_index', type: 'uint256' },
      { internalType: 'uint256', name: '_newSl', type: 'uint256' },
    ],
    name: 'updateSl',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_trader', type: 'address' },
      { internalType: 'uint256', name: '_pairIndex', type: 'uint256' },
      { internalType: 'uint256', name: '_index', type: 'uint256' },
      { internalType: 'uint256', name: '_newTp', type: 'uint256' },
    ],
    name: 'updateTp',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'address', name: 'trader', type: 'address' },
          { internalType: 'uint256', name: 'pairIndex', type: 'uint256' },
          { internalType: 'uint256', name: 'index', type: 'uint256' },
          { internalType: 'uint256', name: 'initialPosToken', type: 'uint256' },
          { internalType: 'uint256', name: 'positionSizeUSDC', type: 'uint256' },
          { internalType: 'uint256', name: 'openPrice', type: 'uint256' },
          { internalType: 'bool', name: 'buy', type: 'bool' },
          { internalType: 'uint256', name: 'leverage', type: 'uint256' },
          { internalType: 'uint256', name: 'tp', type: 'uint256' },
          { internalType: 'uint256', name: 'sl', type: 'uint256' },
          { internalType: 'uint256', name: 'timestamp', type: 'uint256' },
        ],
        internalType: 'struct ITradingStorage.Trade',
        name: '_t',
        type: 'tuple',
      },
    ],
    name: 'updateTrade',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'usdOI',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'usdc',
    outputs: [{ internalType: 'contract IERC20', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'vaultManager',
    outputs: [{ internalType: 'contract IVaultManager', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_trader', type: 'address' }],
    name: 'walletOI',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
] as const;
