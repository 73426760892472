export const PairInfos = [
  { type: 'constructor', inputs: [], stateMutability: 'nonpayable' },
  {
    type: 'function',
    name: 'getAccRolloverFeesLong',
    inputs: [{ name: 'pairIndex', type: 'uint256', internalType: 'uint256' }],
    outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'getAccRolloverFeesShort',
    inputs: [{ name: 'pairIndex', type: 'uint256', internalType: 'uint256' }],
    outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'getAccRolloverFeesUpdateBlock',
    inputs: [{ name: 'pairIndex', type: 'uint256', internalType: 'uint256' }],
    outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'getBlendedSkew',
    inputs: [
      { name: '_pairIndex', type: 'uint256', internalType: 'uint256' },
      { name: '_long', type: 'bool', internalType: 'bool' },
      { name: '_longOI', type: 'uint256', internalType: 'uint256' },
      { name: '_shortOI', type: 'uint256', internalType: 'uint256' },
    ],
    outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'getBlendedUtilizationRatio',
    inputs: [
      { name: '_pairIndex', type: 'uint256', internalType: 'uint256' },
      { name: '_longOI', type: 'uint256', internalType: 'uint256' },
      { name: '_shortOI', type: 'uint256', internalType: 'uint256' },
    ],
    outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'getOnePercentDepthAbove',
    inputs: [{ name: 'pairIndex', type: 'uint256', internalType: 'uint256' }],
    outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'getOnePercentDepthBelow',
    inputs: [{ name: 'pairIndex', type: 'uint256', internalType: 'uint256' }],
    outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'getPairInfos',
    inputs: [{ name: 'indices', type: 'uint256[]', internalType: 'uint256[]' }],
    outputs: [
      {
        name: '',
        type: 'tuple[]',
        internalType: 'struct IPairInfos.PairParams[]',
        components: [
          {
            name: 'onePercentDepthAbove',
            type: 'uint256',
            internalType: 'uint256',
          },
          {
            name: 'onePercentDepthBelow',
            type: 'uint256',
            internalType: 'uint256',
          },
          {
            name: 'rolloverFeePerBlockP',
            type: 'uint256',
            internalType: 'uint256',
          },
        ],
      },
      {
        name: '',
        type: 'tuple[]',
        internalType: 'struct IPairInfos.PairRolloverFees[]',
        components: [
          {
            name: 'accPerOiLong',
            type: 'uint256',
            internalType: 'uint256',
          },
          {
            name: 'accPerOiShort',
            type: 'uint256',
            internalType: 'uint256',
          },
          {
            name: 'lastUpdateBlock',
            type: 'uint256',
            internalType: 'uint256',
          },
        ],
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'getPendingAccRolloverFees',
    inputs: [{ name: 'pairIndex', type: 'uint256', internalType: 'uint256' }],
    outputs: [
      { name: 'valueLong', type: 'uint256', internalType: 'uint256' },
      { name: 'valueShort', type: 'uint256', internalType: 'uint256' },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'getPriceImpactSpread',
    inputs: [
      { name: '_pairIndex', type: 'uint256', internalType: 'uint256' },
      { name: '_isBuy', type: 'bool', internalType: 'bool' },
      {
        name: '_leveragePosition',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [{ name: 'spread', type: 'int256', internalType: 'int256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'getRolloverFeePerBlockP',
    inputs: [{ name: 'pairIndex', type: 'uint256', internalType: 'uint256' }],
    outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'getSkewImpactSpread',
    inputs: [
      { name: '_pairIndex', type: 'uint256', internalType: 'uint256' },
      { name: '_isBuy', type: 'bool', internalType: 'bool' },
      {
        name: '_leveragePosition',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [{ name: 'spread', type: 'int256', internalType: 'int256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'getTradeInitialAccRolloverFeesPerCollateral',
    inputs: [
      { name: 'trader', type: 'address', internalType: 'address' },
      { name: 'pairIndex', type: 'uint256', internalType: 'uint256' },
      { name: 'index', type: 'uint256', internalType: 'uint256' },
    ],
    outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'getTradeLiquidationPrice',
    inputs: [
      { name: 'trader', type: 'address', internalType: 'address' },
      { name: 'pairIndex', type: 'uint256', internalType: 'uint256' },
      { name: 'index', type: 'uint256', internalType: 'uint256' },
      { name: 'openPrice', type: 'uint256', internalType: 'uint256' },
      { name: 'long', type: 'bool', internalType: 'bool' },
      { name: 'collateral', type: 'uint256', internalType: 'uint256' },
      { name: 'leverage', type: 'uint256', internalType: 'uint256' },
    ],
    outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'getTradeLiquidationPricePure',
    inputs: [
      { name: 'openPrice', type: 'uint256', internalType: 'uint256' },
      { name: 'long', type: 'bool', internalType: 'bool' },
      { name: 'collateral', type: 'uint256', internalType: 'uint256' },
      { name: 'leverage', type: 'uint256', internalType: 'uint256' },
      { name: 'rolloverFee', type: 'uint256', internalType: 'uint256' },
    ],
    outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'getTradePriceImpact',
    inputs: [
      { name: 'openPrice', type: 'uint256', internalType: 'uint256' },
      { name: 'pairIndex', type: 'uint256', internalType: 'uint256' },
      { name: 'long', type: 'bool', internalType: 'bool' },
      {
        name: 'tradeOpenInterest',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      {
        name: 'priceAfterImpact',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'getTradeRolloverFee',
    inputs: [
      { name: 'trader', type: 'address', internalType: 'address' },
      { name: 'pairIndex', type: 'uint256', internalType: 'uint256' },
      { name: 'index', type: 'uint256', internalType: 'uint256' },
      { name: 'long', type: 'bool', internalType: 'bool' },
      { name: 'collateral', type: 'uint256', internalType: 'uint256' },
      { name: 'leverage', type: 'uint256', internalType: 'uint256' },
    ],
    outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'getTradeRolloverFeePure',
    inputs: [
      {
        name: 'accRolloverFeesPerOi',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'endAccRolloverFeesPerOi',
        type: 'uint256',
        internalType: 'uint256',
      },
      { name: 'collateral', type: 'uint256', internalType: 'uint256' },
      { name: 'leverage', type: 'uint256', internalType: 'uint256' },
    ],
    outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
    stateMutability: 'pure',
  },
  {
    type: 'function',
    name: 'getTradeValue',
    inputs: [
      {
        name: '_trade',
        type: 'tuple',
        internalType: 'struct ITradingStorage.Trade',
        components: [
          { name: 'trader', type: 'address', internalType: 'address' },
          {
            name: 'pairIndex',
            type: 'uint256',
            internalType: 'uint256',
          },
          { name: 'index', type: 'uint256', internalType: 'uint256' },
          {
            name: 'initialPosToken',
            type: 'uint256',
            internalType: 'uint256',
          },
          {
            name: 'positionSizeUSDC',
            type: 'uint256',
            internalType: 'uint256',
          },
          {
            name: 'openPrice',
            type: 'uint256',
            internalType: 'uint256',
          },
          { name: 'buy', type: 'bool', internalType: 'bool' },
          {
            name: 'leverage',
            type: 'uint256',
            internalType: 'uint256',
          },
          { name: 'tp', type: 'uint256', internalType: 'uint256' },
          { name: 'sl', type: 'uint256', internalType: 'uint256' },
          {
            name: 'timestamp',
            type: 'uint256',
            internalType: 'uint256',
          },
        ],
      },
      { name: 'collateral', type: 'uint256', internalType: 'uint256' },
      { name: 'percentProfit', type: 'int256', internalType: 'int256' },
      { name: 'closingFee', type: 'uint256', internalType: 'uint256' },
      { name: '_tier', type: 'uint256', internalType: 'uint256' },
    ],
    outputs: [
      { name: 'amount', type: 'uint256', internalType: 'uint256' },
      { name: 'pnl', type: 'int256', internalType: 'int256' },
      { name: 'fees', type: 'uint256', internalType: 'uint256' },
    ],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'getTradeValuePure',
    inputs: [
      { name: 'collateral', type: 'uint256', internalType: 'uint256' },
      { name: 'percentProfit', type: 'int256', internalType: 'int256' },
      { name: 'rolloverFee', type: 'uint256', internalType: 'uint256' },
      { name: 'closingFee', type: 'uint256', internalType: 'uint256' },
      {
        name: 'lossProtection',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [
      { name: '', type: 'uint256', internalType: 'uint256' },
      { name: '', type: 'int256', internalType: 'int256' },
      { name: '', type: 'uint256', internalType: 'uint256' },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'initialize',
    inputs: [
      { name: '_storageT', type: 'address', internalType: 'address' },
      {
        name: '_pairsStorage',
        type: 'address',
        internalType: 'address',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'keeper',
    inputs: [],
    outputs: [{ name: '', type: 'address', internalType: 'address' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'liqThreshold',
    inputs: [],
    outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'longSkewConfig',
    inputs: [
      { name: '', type: 'uint256', internalType: 'uint256' },
      { name: '', type: 'uint256', internalType: 'uint256' },
    ],
    outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'lossProtectionNumTiers',
    inputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
    outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'lossProtectionTier',
    inputs: [
      {
        name: '_trade',
        type: 'tuple',
        internalType: 'struct ITradingStorage.Trade',
        components: [
          { name: 'trader', type: 'address', internalType: 'address' },
          {
            name: 'pairIndex',
            type: 'uint256',
            internalType: 'uint256',
          },
          { name: 'index', type: 'uint256', internalType: 'uint256' },
          {
            name: 'initialPosToken',
            type: 'uint256',
            internalType: 'uint256',
          },
          {
            name: 'positionSizeUSDC',
            type: 'uint256',
            internalType: 'uint256',
          },
          {
            name: 'openPrice',
            type: 'uint256',
            internalType: 'uint256',
          },
          { name: 'buy', type: 'bool', internalType: 'bool' },
          {
            name: 'leverage',
            type: 'uint256',
            internalType: 'uint256',
          },
          { name: 'tp', type: 'uint256', internalType: 'uint256' },
          { name: 'sl', type: 'uint256', internalType: 'uint256' },
          {
            name: 'timestamp',
            type: 'uint256',
            internalType: 'uint256',
          },
        ],
      },
    ],
    outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'manager',
    inputs: [],
    outputs: [{ name: '', type: 'address', internalType: 'address' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'pairParams',
    inputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
    outputs: [
      {
        name: 'onePercentDepthAbove',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'onePercentDepthBelow',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'rolloverFeePerBlockP',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'pairRolloverFees',
    inputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
    outputs: [
      {
        name: 'accPerOiLong',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'accPerOiShort',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'lastUpdateBlock',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'pairsStorage',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'address',
        internalType: 'contract IPairStorage',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'setKeeper',
    inputs: [{ name: '_keeper', type: 'address', internalType: 'address' }],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'setLossProtectionConfig',
    inputs: [
      { name: '_pairIndex', type: 'uint256', internalType: 'uint256' },
      {
        name: '_longSkewConfig',
        type: 'uint256[]',
        internalType: 'uint256[]',
      },
      {
        name: '_shortSkewConfig',
        type: 'uint256[]',
        internalType: 'uint256[]',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'setManager',
    inputs: [{ name: '_manager', type: 'address', internalType: 'address' }],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'setOnePercentDepth',
    inputs: [
      { name: 'pairIndex', type: 'uint256', internalType: 'uint256' },
      { name: 'valueAbove', type: 'uint256', internalType: 'uint256' },
      { name: 'valueBelow', type: 'uint256', internalType: 'uint256' },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'setOnePercentDepthArray',
    inputs: [
      { name: 'indices', type: 'uint256[]', internalType: 'uint256[]' },
      {
        name: 'valuesAbove',
        type: 'uint256[]',
        internalType: 'uint256[]',
      },
      {
        name: 'valuesBelow',
        type: 'uint256[]',
        internalType: 'uint256[]',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'setPairParams',
    inputs: [
      { name: 'pairIndex', type: 'uint256', internalType: 'uint256' },
      {
        name: 'value',
        type: 'tuple',
        internalType: 'struct IPairInfos.PairParams',
        components: [
          {
            name: 'onePercentDepthAbove',
            type: 'uint256',
            internalType: 'uint256',
          },
          {
            name: 'onePercentDepthBelow',
            type: 'uint256',
            internalType: 'uint256',
          },
          {
            name: 'rolloverFeePerBlockP',
            type: 'uint256',
            internalType: 'uint256',
          },
        ],
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'setPairParamsArray',
    inputs: [
      { name: 'indices', type: 'uint256[]', internalType: 'uint256[]' },
      {
        name: 'values',
        type: 'tuple[]',
        internalType: 'struct IPairInfos.PairParams[]',
        components: [
          {
            name: 'onePercentDepthAbove',
            type: 'uint256',
            internalType: 'uint256',
          },
          {
            name: 'onePercentDepthBelow',
            type: 'uint256',
            internalType: 'uint256',
          },
          {
            name: 'rolloverFeePerBlockP',
            type: 'uint256',
            internalType: 'uint256',
          },
        ],
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'setRolloverFeePerBlockP',
    inputs: [
      { name: 'pairIndex', type: 'uint256', internalType: 'uint256' },
      { name: 'value', type: 'uint256', internalType: 'uint256' },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'shortSkewConfig',
    inputs: [
      { name: '', type: 'uint256', internalType: 'uint256' },
      { name: '', type: 'uint256', internalType: 'uint256' },
    ],
    outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'storageT',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'address',
        internalType: 'contract ITradingStorage',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'storeTradeInitialAccFees',
    inputs: [
      { name: 'trader', type: 'address', internalType: 'address' },
      { name: 'pairIndex', type: 'uint256', internalType: 'uint256' },
      { name: 'index', type: 'uint256', internalType: 'uint256' },
      { name: 'long', type: 'bool', internalType: 'bool' },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'tradeInitialAccFees',
    inputs: [
      { name: '', type: 'address', internalType: 'address' },
      { name: '', type: 'uint256', internalType: 'uint256' },
      { name: '', type: 'uint256', internalType: 'uint256' },
    ],
    outputs: [
      { name: 'rollover', type: 'uint256', internalType: 'uint256' },
      { name: 'openedAfterUpdate', type: 'bool', internalType: 'bool' },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'udpateLiquidationThreshold',
    inputs: [
      {
        name: '_newThreshold',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'event',
    name: 'AccRolloverFeesStored',
    inputs: [
      {
        name: 'pairIndex',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'valueLong',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'valueShort',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'CoeffUpdated',
    inputs: [
      {
        name: 'multiplierCoeffMax',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'groupId',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'DenomUpdated',
    inputs: [
      {
        name: 'multiplierDenom',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'groupId',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'FeesCharged',
    inputs: [
      {
        name: 'buy',
        type: 'bool',
        indexed: false,
        internalType: 'bool',
      },
      {
        name: 'collateral',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'leverage',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'percentProfit',
        type: 'int256',
        indexed: false,
        internalType: 'int256',
      },
      {
        name: 'r',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'closingFee',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'lossProtection',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'Initialized',
    inputs: [
      {
        name: 'version',
        type: 'uint8',
        indexed: false,
        internalType: 'uint8',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'KeeperUpdated',
    inputs: [
      {
        name: 'keeper',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'LiqThresholdUpdated',
    inputs: [
      {
        name: 'newThreshold',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'LossProtectionConfigSet',
    inputs: [
      {
        name: 'numTiers',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'longSkewConfig',
        type: 'uint256[]',
        indexed: false,
        internalType: 'uint256[]',
      },
      {
        name: 'shortSkewConfig',
        type: 'uint256[]',
        indexed: false,
        internalType: 'uint256[]',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'ManagerUpdated',
    inputs: [
      {
        name: 'value',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'MaxNegativePnlOnOpenPUpdated',
    inputs: [
      {
        name: 'value',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'MultiplierUpdated',
    inputs: [
      {
        name: 'minMultiplierRate',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'maxMultiplierRate',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'groupId',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'OnePercentDepthUpdated',
    inputs: [
      {
        name: 'pairIndex',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'valueAbove',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'valueBelow',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'PairParamsUpdated',
    inputs: [
      {
        name: 'pairIndex',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'value',
        type: 'tuple',
        indexed: false,
        internalType: 'struct IPairInfos.PairParams',
        components: [
          {
            name: 'onePercentDepthAbove',
            type: 'uint256',
            internalType: 'uint256',
          },
          {
            name: 'onePercentDepthBelow',
            type: 'uint256',
            internalType: 'uint256',
          },
          {
            name: 'rolloverFeePerBlockP',
            type: 'uint256',
            internalType: 'uint256',
          },
        ],
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'RolloverFeePerBlockPUpdated',
    inputs: [
      {
        name: 'pairIndex',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'fee',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'TradeInitialAccFeesStored',
    inputs: [
      {
        name: 'trader',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
      {
        name: 'pairIndex',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'index',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'rollover',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
] as const;
