import { createPublicClient, PublicClient } from 'viem';
import { createConfig, http } from 'wagmi';
import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import { DEDICATED_RPC, PROJECT_ID, PUBLIC_RPC } from '@/lib/env';
import { base, mainnet } from 'viem/chains';
import {
  walletConnectWallet,
  injectedWallet,
  coinbaseWallet,
  rainbowWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { fallback } from 'wagmi';

declare module 'wagmi' {
  interface Register {
    config: typeof config;
  }
}

export const mainnetConfig = createConfig({
  chains: [mainnet],
  transports: {
    [mainnet.id]: http(),
  },
});

export const ethereumClient: PublicClient = createPublicClient({
  chain: mainnet,
  transport: http('https://eth-mainnet.public.blastapi.io'),
});

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended',
      wallets: [injectedWallet, walletConnectWallet, coinbaseWallet, rainbowWallet],
    },
  ],
  {
    appName: 'Avantis',
    projectId: PROJECT_ID,
  }
);

export const config = createConfig({
  connectors,
  chains: [base],
  ssr: true,
  // transports: {
  //   [base.id]: custom({
  //     request: async (req) => {
  //       let response;
  //       try {
  //         response = await readPublicClient.request(req);
  //       } catch (error) {
  //         console.log('rpc request failed with', error);
  //         response = await writePublicClient.request(req);
  //       }
  //       return response;
  //     },

  //   }),
  // },
  transports: {
    [base.id]: fallback([http(PUBLIC_RPC), http(DEDICATED_RPC)]),
  },
});

export const blockchainExplorerUrl = base.blockExplorers.default.url;
