export const Trading = [
  { type: 'constructor', inputs: [], stateMutability: 'nonpayable' },
  {
    type: 'function',
    name: 'addWhitelist',
    inputs: [{ name: '_address', type: 'address', internalType: 'address' }],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'cancelOpenLimitOrder',
    inputs: [
      { name: '_pairIndex', type: 'uint256', internalType: 'uint256' },
      { name: '_index', type: 'uint256', internalType: 'uint256' },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'cancelPendingMarketOrder',
    inputs: [{ name: '_id', type: 'uint256', internalType: 'uint256' }],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'closeTradeMarket',
    inputs: [
      { name: '_pairIndex', type: 'uint256', internalType: 'uint256' },
      { name: '_index', type: 'uint256', internalType: 'uint256' },
      { name: '_amount', type: 'uint256', internalType: 'uint256' },
      {
        name: '_executionFee',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [{ name: 'orderId', type: 'uint256', internalType: 'uint256' }],
    stateMutability: 'payable',
  },
  {
    type: 'function',
    name: 'executeLimitOrder',
    inputs: [
      {
        name: '_orderType',
        type: 'uint8',
        internalType: 'enum ITradingStorage.LimitOrder',
      },
      { name: '_trader', type: 'address', internalType: 'address' },
      { name: '_pairIndex', type: 'uint256', internalType: 'uint256' },
      { name: '_index', type: 'uint256', internalType: 'uint256' },
      {
        name: 'priceUpdateData',
        type: 'bytes[]',
        internalType: 'bytes[]',
      },
    ],
    outputs: [],
    stateMutability: 'payable',
  },
  {
    type: 'function',
    name: 'executeMarketOrders',
    inputs: [
      { name: 'pairIndex', type: 'uint256', internalType: 'uint256' },
      { name: 'orderId', type: 'uint256[]', internalType: 'uint256[]' },
      {
        name: 'priceUpdateData',
        type: 'bytes[]',
        internalType: 'bytes[]',
      },
    ],
    outputs: [],
    stateMutability: 'payable',
  },
  {
    type: 'function',
    name: 'initialize',
    inputs: [
      { name: '_storageT', type: 'address', internalType: 'address' },
      { name: '_pairInfos', type: 'address', internalType: 'address' },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'isOperator',
    inputs: [{ name: '', type: 'address', internalType: 'address' }],
    outputs: [{ name: '', type: 'bool', internalType: 'bool' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'isWhitelisted',
    inputs: [],
    outputs: [{ name: '', type: 'bool', internalType: 'bool' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'lastUpdateTime',
    inputs: [
      { name: '', type: 'address', internalType: 'address' },
      { name: '', type: 'uint256', internalType: 'uint256' },
    ],
    outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'limitOrdersTimelock',
    inputs: [],
    outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'openTrade',
    inputs: [
      {
        name: 't',
        type: 'tuple',
        internalType: 'struct ITradingStorage.Trade',
        components: [
          { name: 'trader', type: 'address', internalType: 'address' },
          {
            name: 'pairIndex',
            type: 'uint256',
            internalType: 'uint256',
          },
          { name: 'index', type: 'uint256', internalType: 'uint256' },
          {
            name: 'initialPosToken',
            type: 'uint256',
            internalType: 'uint256',
          },
          {
            name: 'positionSizeUSDC',
            type: 'uint256',
            internalType: 'uint256',
          },
          {
            name: 'openPrice',
            type: 'uint256',
            internalType: 'uint256',
          },
          { name: 'buy', type: 'bool', internalType: 'bool' },
          {
            name: 'leverage',
            type: 'uint256',
            internalType: 'uint256',
          },
          { name: 'tp', type: 'uint256', internalType: 'uint256' },
          { name: 'sl', type: 'uint256', internalType: 'uint256' },
          {
            name: 'timestamp',
            type: 'uint256',
            internalType: 'uint256',
          },
        ],
      },
      {
        name: '_type',
        type: 'uint8',
        internalType: 'enum IExecute.OpenLimitOrderType',
      },
      { name: '_slippageP', type: 'uint256', internalType: 'uint256' },
      {
        name: '_executionFee',
        type: 'uint256',
        internalType: 'uint256',
      },
    ],
    outputs: [{ name: 'orderId', type: 'uint256', internalType: 'uint256' }],
    stateMutability: 'payable',
  },
  {
    type: 'function',
    name: 'operator',
    inputs: [],
    outputs: [{ name: '', type: 'address', internalType: 'address' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'pairInfos',
    inputs: [],
    outputs: [{ name: '', type: 'address', internalType: 'contract IPairInfos' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'pause',
    inputs: [],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'paused',
    inputs: [],
    outputs: [{ name: '', type: 'bool', internalType: 'bool' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'removeWhitelist',
    inputs: [{ name: '_address', type: 'address', internalType: 'address' }],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'setLimitOrdersTimelock',
    inputs: [{ name: '_blocks', type: 'uint256', internalType: 'uint256' }],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'setMarketExecFeeReciever',
    inputs: [{ name: '_reciever', type: 'address', internalType: 'address' }],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'storageT',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'address',
        internalType: 'contract ITradingStorage',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'toggleWhitelist',
    inputs: [],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'unpause',
    inputs: [],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'updateMargin',
    inputs: [
      { name: '_pairIndex', type: 'uint256', internalType: 'uint256' },
      { name: '_index', type: 'uint256', internalType: 'uint256' },
      {
        name: '_type',
        type: 'uint8',
        internalType: 'enum ITradingStorage.updateType',
      },
      { name: '_amount', type: 'uint256', internalType: 'uint256' },
      {
        name: 'priceUpdateData',
        type: 'bytes[]',
        internalType: 'bytes[]',
      },
    ],
    outputs: [{ name: 'orderId', type: 'uint256', internalType: 'uint256' }],
    stateMutability: 'payable',
  },
  {
    type: 'function',
    name: 'updateOpenLimitOrder',
    inputs: [
      { name: '_pairIndex', type: 'uint256', internalType: 'uint256' },
      { name: '_index', type: 'uint256', internalType: 'uint256' },
      { name: '_price', type: 'uint256', internalType: 'uint256' },
      { name: '_slippageP', type: 'uint256', internalType: 'uint256' },
      { name: '_tp', type: 'uint256', internalType: 'uint256' },
      { name: '_sl', type: 'uint256', internalType: 'uint256' },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'updateOperator',
    inputs: [
      { name: '_operator', type: 'address', internalType: 'address' },
      { name: '_isOperator', type: 'bool', internalType: 'bool' },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'updateSl',
    inputs: [
      { name: '_pairIndex', type: 'uint256', internalType: 'uint256' },
      { name: '_index', type: 'uint256', internalType: 'uint256' },
      { name: '_newSl', type: 'uint256', internalType: 'uint256' },
      {
        name: 'priceUpdateData',
        type: 'bytes[]',
        internalType: 'bytes[]',
      },
    ],
    outputs: [],
    stateMutability: 'payable',
  },
  {
    type: 'function',
    name: 'updateTp',
    inputs: [
      { name: '_pairIndex', type: 'uint256', internalType: 'uint256' },
      { name: '_index', type: 'uint256', internalType: 'uint256' },
      { name: '_newTp', type: 'uint256', internalType: 'uint256' },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'updateTpAndSl',
    inputs: [
      { name: '_pairIndex', type: 'uint256', internalType: 'uint256' },
      { name: '_index', type: 'uint256', internalType: 'uint256' },
      { name: '_newSl', type: 'uint256', internalType: 'uint256' },
      { name: '_newTP', type: 'uint256', internalType: 'uint256' },
      {
        name: 'priceUpdateData',
        type: 'bytes[]',
        internalType: 'bytes[]',
      },
    ],
    outputs: [],
    stateMutability: 'payable',
  },
  {
    type: 'function',
    name: 'whitelistedAddress',
    inputs: [{ name: '', type: 'address', internalType: 'address' }],
    outputs: [{ name: '', type: 'bool', internalType: 'bool' }],
    stateMutability: 'view',
  },
  {
    type: 'event',
    name: 'AddressUpdated',
    inputs: [
      {
        name: 'name',
        type: 'string',
        indexed: false,
        internalType: 'string',
      },
      {
        name: 'a',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'Initialized',
    inputs: [
      {
        name: 'version',
        type: 'uint8',
        indexed: false,
        internalType: 'uint8',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'LimitOrderInitiated',
    inputs: [
      {
        name: 'trader',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
      {
        name: 'pairIndex',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'orderId',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'timestamp',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'LimitOrderSameBlock',
    inputs: [
      {
        name: 'trader',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
      {
        name: 'pairIndex',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'timestamp',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'MarginUpdated',
    inputs: [
      {
        name: 'trader',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
      {
        name: 'pairIndex',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'index',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: '_type',
        type: 'uint8',
        indexed: false,
        internalType: 'enum ITradingStorage.updateType',
      },
      {
        name: 'newTrade',
        type: 'tuple',
        indexed: false,
        internalType: 'struct ITradingStorage.Trade',
        components: [
          { name: 'trader', type: 'address', internalType: 'address' },
          {
            name: 'pairIndex',
            type: 'uint256',
            internalType: 'uint256',
          },
          { name: 'index', type: 'uint256', internalType: 'uint256' },
          {
            name: 'initialPosToken',
            type: 'uint256',
            internalType: 'uint256',
          },
          {
            name: 'positionSizeUSDC',
            type: 'uint256',
            internalType: 'uint256',
          },
          {
            name: 'openPrice',
            type: 'uint256',
            internalType: 'uint256',
          },
          { name: 'buy', type: 'bool', internalType: 'bool' },
          {
            name: 'leverage',
            type: 'uint256',
            internalType: 'uint256',
          },
          { name: 'tp', type: 'uint256', internalType: 'uint256' },
          { name: 'sl', type: 'uint256', internalType: 'uint256' },
          {
            name: 'timestamp',
            type: 'uint256',
            internalType: 'uint256',
          },
        ],
      },
      {
        name: 'marginFees',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'lossProtectionTier',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'timestamp',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'MarketOrderInitiated',
    inputs: [
      {
        name: 'trader',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
      {
        name: 'pairIndex',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'open',
        type: 'bool',
        indexed: false,
        internalType: 'bool',
      },
      {
        name: 'orderId',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'timestamp',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'NumberUpdated',
    inputs: [
      {
        name: 'name',
        type: 'string',
        indexed: false,
        internalType: 'string',
      },
      {
        name: 'value',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'OpenLimitCanceled',
    inputs: [
      {
        name: 'trader',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
      {
        name: 'pairIndex',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'index',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'timestamp',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'OpenLimitPlaced',
    inputs: [
      {
        name: 'trader',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
      {
        name: 'pairIndex',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'index',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'isBuy',
        type: 'bool',
        indexed: false,
        internalType: 'bool',
      },
      {
        name: 'openPrice',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'executionFee',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'orderType',
        type: 'uint8',
        indexed: false,
        internalType: 'enum IExecute.OpenLimitOrderType',
      },
      {
        name: 'slippageP',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'OpenLimitUpdated',
    inputs: [
      {
        name: 'trader',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
      {
        name: 'pairIndex',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'index',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'newPrice',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'newTp',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'newSl',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'timestamp',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'OperatorUpdated',
    inputs: [
      {
        name: 'operator',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
      {
        name: 'isOperator',
        type: 'bool',
        indexed: false,
        internalType: 'bool',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'Paused',
    inputs: [
      {
        name: 'account',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'SlUpdateInitiated',
    inputs: [
      {
        name: 'trader',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
      {
        name: 'pairIndex',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'index',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'newSl',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'orderId',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'timestamp',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'SlUpdated',
    inputs: [
      {
        name: 'trader',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
      {
        name: 'pairIndex',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'index',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'newSl',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'timestamp',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'TpUpdated',
    inputs: [
      {
        name: 'trader',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
      {
        name: 'pairIndex',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'index',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'newTp',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'timestamp',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'Unpaused',
    inputs: [
      {
        name: 'account',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'Whitelist',
    inputs: [
      {
        name: 'whitelist',
        type: 'bool',
        indexed: false,
        internalType: 'bool',
      },
    ],
    anonymous: false,
  },
] as const;
