import { Abi, Address, Hash } from 'viem';

import { default as TradingCallbacks } from '@/blockchain/contracts/TradingCallbacks.sol/TradingCallbacks.json';
import { default as PairStorage } from '@/blockchain/contracts/PairStorage.sol/PairStorage.json';
import { default as AggregatorV3Interface } from '@/blockchain/contracts/AggregatorV3Interface.sol/AggregatorV3Interface.json';
import { default as IPyth } from '@/blockchain/contracts/IPyth.sol/IPyth.json';
import { default as VeTranche } from '@/blockchain/contracts/VeTranche.sol/VeTranche.json';

import { default as VaultManager } from '@/blockchain/contracts/VaultManager.sol/VaultManager.json';
import { default as Execute } from '@/blockchain/contracts/Execute.sol/Execute.json';
import { default as Sanctions } from '@/blockchain/contracts/Sanctions.json';
import { Rewards } from './Rewards.sol/Rewards';
import { TradingStorage } from './TradingStorage.sol/TradingStorage';
import { Multicall } from './Multicall.sol/Multicall';
import { USDC } from './USDC.sol/USDC';
import { Trading } from './Trading.sol/Trading';
import { PairInfos } from './PairInfos.sol/PairInfos';
import { Tranche } from './Tranche.sol/Tranche';
import { Referral } from './Referral.sol/Referral';

export const sanctions = {
  abi: Sanctions.abi as Abi,
  address: '0x40c57923924b5c5c5455c48d93317139addac8fb' as Address,
};

export const contractsConfig: Record<string, { address: Address; abi: Abi }> = {
  TradingStorage: {
    address: '0x8a311D7048c35985aa31C131B9A13e03a5f7422d' as Address,
    abi: TradingStorage,
  },
  PairStorage: {
    address: '0x92Ed158d5e423CFdc9eed5Bd7328FFF7CeD6fF94' as Address,
    abi: PairStorage.abi as Abi,
  },
  PairInfos: {
    address: '0x81F22d0Cc22977c91bEfE648C9fddf1f2bd977e5' as Address,
    abi: PairInfos,
  },
  Trading: {
    address: '0x5FF292d70bA9cD9e7CCb313782811b3D7120535f' as Address,
    abi: Trading,
  },
  TradingCallbacks: {
    address: '0x0C16ff40065Cc3Ab4bc55B60E447504AFB9C7970' as Address,
    abi: TradingCallbacks.abi as Abi,
  },
  Execute: {
    address: '0xdbDd7B8a8747904f53eb7AEF655a6FF81e2c306a' as Address,
    abi: Execute.abi as Abi,
  },
  VaultManager: {
    address: '0xe9fB8C70aF1b99F2Baaa07Aa926FCf3d237348DD' as Address,
    abi: VaultManager.abi as Abi,
  },
  Junior: {
    address: '0x944766f715b51967E56aFdE5f0Aa76cEaCc9E7f9' as Address,
    abi: Tranche as Abi,
  },
  Senior: {
    address: '0x83084cB182162473d6FEFfCd3Aa48BA55a7B66F7' as Address,
    abi: Tranche as Abi,
  },
  VeJunior: {
    address: '0x7BF094c44B3cFF8C95e06a76557443F5408efB05' as Address,
    abi: VeTranche.abi as Abi,
  },
  VeSenior: {
    address: '0x6914110eFe4E61cFa0F28dE5f6606bAa33D21693' as Address,
    abi: VeTranche.abi as Abi,
  },
  IPyth: {
    address: '0x8250f4aF4B972684F7b336503E2D6dFeDeB1487a' as Address,
    abi: IPyth.abi as Abi,
  },
  Referral: {
    address: '0xA96f577821933d127B491D0F91202405B0dbB1bd' as Address,
    abi: Referral,
  },
  AggregatorV3Interface: {
    abi: AggregatorV3Interface.abi as Abi,
    address: '' as Hash,
  },
  USDC: {
    address: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913' as Address,
    abi: USDC,
  },
  Multicall: {
    address: '0x467963d59817130c01b88036B9d77dCa5f53259e' as Address,
    abi: Multicall,
  },
  Rewards: {
    address: '0x46484c5b06627b79e21b335c7808995d2d4488d8' as Address,
    abi: Rewards,
  },
};
